import { Link, VisuallyHidden } from '@overdose/components'
import { IconArrowRight } from '@tabler/icons-react'
import classNames from 'classnames'
import { Image } from '~/components/Image'
import { getImageSrc } from '~/helpers'
import { useAlgoliaSearchInsignts } from '~/hooks'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './CategoryCard.module.css'
import { CategoryCardProps } from './CategoryCard.types'

export const CategoryCard = ({
  image,
  title,
  action,
  backgroundColor,
  isDesktopCarouselDisabled,
  categorySku,
}: CategoryCardProps) => {
  const isHexColor = backgroundColor !== 'dark' && backgroundColor !== 'light'
  const { sendViewProductsEvent } = useAlgoliaSearchInsignts()

  const renderOverlayLink = () => {
    if (!action?.url || action.url.startsWith('/url/')) {
      return null
    }

    return (
      <Link
        className='absolute z-10 inset-0'
        to={action.url}
        onClick={() => {
          sendViewProductsEvent([
            {
              title,
              url: action.url,
              listId: categorySku,
            },
          ])
        }}
      />
    )
  }

  return (
    <div
      className={classNames(
        backgroundColor === 'dark' && 'bg-background-thumb',
        backgroundColor === 'light' && 'bg-white',
        styles.card,
        isHexColor && styles.hexColoredCard,
        isDesktopCarouselDisabled && styles.desktopCarouselDisabledCard,
        'p-4 md:p-6 relative rounded-md overflow-hidden'
      )}
      style={{ backgroundColor: isHexColor ? `${backgroundColor}` : 'none' }}>
      {renderOverlayLink()}
      {image && image?.src?.length > 0 && (
        <Image
          title={title}
          src={getImageSrc(image.src, '350')}
          addSrcSet={false}
          alt={image.altText}
          width={image.width}
          height={image.height}
          className={classNames(
            styles.image,
            'object-cover absolute inset-0 h-full w-full'
          )}
        />
      )}
      <Typography
        tag={TypographyTag.p}
        variant={TypographyVariant.Heading6}
        className={classNames(
          styles.title,
          backgroundColor === 'dark' ? '!text-white' : ''
        )}>
        {title}
      </Typography>
      {action && !action.url.startsWith('/url/') && (
        <div
          className={classNames(
            'absolute lg:bottom-6 bottom-4 lg:py-3.5 lg:px-4 py-2 px-3 bg-white rounded',
            styles.actionContainer
          )}>
          <Typography
            tag={TypographyTag.span}
            variant={TypographyVariant.BodyRegularBold}
            className='text-primary-heading'>
            {action.linkText}
          </Typography>
          <IconArrowRight
            size={20}
            color='var(--btn-solid-color-text-secondary)'
            strokeWidth={2}
            className='inline-block ml-2'
          />
          <VisuallyHidden>Browse {title}</VisuallyHidden>
        </div>
      )}
    </div>
  )
}
