import React, { useCallback, useId, useState } from 'react'
import { useMedia } from 'react-use'
import { Link } from '@overdose/components'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import classNames from 'classnames'
import type { Swiper as SwiperType } from 'swiper'
import { Controller, Navigation, Scrollbar, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Button, CategoryCard, ProductCard, ProjectCard } from '~/components'
import { Image as Images } from '~/components/Image'
import { SectionContainer } from '~/components/SectionContainer'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { getImageSrc } from '~/helpers'
import { useDebounce } from '~/hooks'
import styles from './Cards.module.css'
import { ICards, CardTypes } from './Cards.types'
import 'swiper/css'
import 'swiper/css/scrollbar'
import { NavigationButton } from './NavigationButton'

const CardSwiper = ({
  isCardTypeCategoryCard,
  cards,
  isMarketingTile,
  marketingTile,
  marketingTileAtStart,
  marketingTileAtEnd,
  seeMoreUrl,
  width = '',
  setIsLoaded,
}) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null)
  const swiperId = useId().replace(/\/|\s|\:/gm, '_')
  const isTablet = useMedia('(min-width: 768px) and (max-width: 1180px)')
  const isDesktop = useMedia('(min-width: 1181px)', false)

  let imageSrc = marketingTile?.desktop?.src
  let imageAlt = marketingTile?.desktop?.alt || ''
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)
  if (isTablet) {
    imageSrc = marketingTile?.tablet?.src || imageSrc
    imageAlt = marketingTile?.tablet?.alt || imageAlt
  } else if (!isDesktop) {
    imageSrc = marketingTile?.mobile?.src || imageSrc
    imageAlt = marketingTile?.mobile?.alt || imageAlt
  }

  const renderMarketingTileContent = (seeMoreUrl) => {
    return (
      <SwiperSlide className={classNames('!h-auto', styles.marketingTileSlide)}>
        <Link to={seeMoreUrl}>
          {imageSrc && (
            <Images
              src={imageSrc}
              alt={imageAlt}
              className='w-full !h-auto'
              style={{ borderRadius: '16px' }}
              fill={false}
            />
          )}
        </Link>
      </SwiperSlide>
    )
  }

  const handleNextAndPrevButtonsVisibility = useCallback(() => {
    setIsBeginning(swiper.isBeginning)
    setIsEnd(swiper.isEnd)
  }, [swiper])

  const handleSlidePrev = useCallback(() => {
    if (!swiper) {
      return
    }
    swiper.slidePrev()
    handleNextAndPrevButtonsVisibility()
  }, [swiper, handleNextAndPrevButtonsVisibility])

  const handleSlideNext = useCallback(() => {
    if (!swiper) {
      return
    }
    swiper.slideNext()
    handleNextAndPrevButtonsVisibility()
  }, [swiper, handleNextAndPrevButtonsVisibility])

  const handleSwiperRender = () => {
    setIsLoaded(true)
  }

  return (
    <div
      className={classNames(
        {
          [styles.categoryCardSwiperRoot]: isCardTypeCategoryCard,
          [styles.projectCardSwiperRoot]: !isCardTypeCategoryCard,
          [styles.marketingTile]:
            isMarketingTile && (marketingTileAtStart || marketingTileAtEnd),
        },
        'flex relative justify-center'
      )}>
      {isMarketingTile && (
        <div
          className={classNames('hidden md:min-[1080px]:flex gap-1', {
            [styles.swiperButton]: isMarketingTile,
          })}>
          {(swiper?.allowSlideNext || swiper?.allowSlidePrev) && (
            <>
              <NavigationButton
                icon={<IconChevronLeft />}
                onClick={handleSlidePrev}
                disabled={isBeginning}
                label='previous'
              />
              <NavigationButton
                icon={<IconChevronRight />}
                onClick={handleSlideNext}
                disabled={isEnd}
                label='next'
              />
            </>
          )}
        </div>
      )}
      <Swiper
        spaceBetween={16}
        modules={[Controller, Navigation, Scrollbar, A11y]}
        controller={{ control: swiper }}
        onSwiper={setSwiper}
        slidesPerView='auto'
        centerInsufficientSlides
        onSlidesGridLengthChange={() => {
          return handleSwiperRender()
        }}
        scrollbar={{
          draggable: true,
          el: `.${swiperId}`,
          dragClass: styles.draggableElement,
        }}>
        {isMarketingTile &&
          marketingTileAtStart &&
          renderMarketingTileContent(seeMoreUrl)}
        {cards?.map((card, i) => {
          return (
            <SwiperSlide key={i} className={classNames(
                '!h-auto',
                isMarketingTile && styles.marketingTileSlide
              )}>
              {card.cardType === CardTypes.CATEGORY && (
                <CategoryCard {...card} key={i} />
              )}
              {card.cardType === CardTypes.PRODUCT && (
                <ProductCard
                  {...card}
                  showRating={!isMarketingTile}
                  key={i}
                  showCompactAddToCart={width === 'narrow'}
                />
              )}
              {card.cardType === CardTypes.PROJECT && (
                <ProjectCard {...card} key={i} />
              )}
            </SwiperSlide>
          )
        })}
        {isMarketingTile &&
          marketingTileAtEnd &&
          renderMarketingTileContent(seeMoreUrl)}
      </Swiper>
      <div
        className={classNames(
          'swiper-scrollbar !-bottom-6 !h-[2px]',
          swiperId,
          styles.scrollbarContainer
        )}
      />
    </div>
  )
}

export const Cards = ({
  title,
  paragraph,
  seeMoreText,
  seeMoreUrl,
  cards,
  backgroundColor,
  width,
  image,
  isDesktopCarouselDisabled,
  isMobileCarouselDisabled,
  rowItemCountOnDesktop,
  rowItemCountOnMobile,
  categorySku,
  marketingTile,
  marketingTileAtStart,
  marketingTileAtEnd,
}: ICards) => {
  const isCardTypeCategoryCard = cards?.[0]?.cardType === CardTypes.CATEGORY
  const isCartTypeProductCard = cards?.[0]?.cardType === CardTypes.PRODUCT
  const isCartTypeProjectCard = cards?.[0]?.cardType === CardTypes.PROJECT
  const DEBOUNCE_TIME = 500
  const hasImage = !!image?.src
  const hasHeading = !!title || !!paragraph

  const isMarketingTile =
    marketingTile?.desktop && cards?.[0]?.cardType === CardTypes.PRODUCT

  const [isLoaded, setIsLoaded] = useState(false)
  const debouIsLoaded = useDebounce(isLoaded, DEBOUNCE_TIME)

  return (
    <SectionContainer
      width={width}
      wrapperClassName={classNames(
        backgroundColor === 'dark' ? 'bg-background-thumb' : 'bg-white',
        styles.root,
        debouIsLoaded ? 'visible' : 'invisible !h-0 overflow-hidden'
      )}
      innerClassName={classNames(hasImage ? styles.wrapperContainer : '')}>
      {hasImage && (
        <div className='hidden lg:block relative w-full lg:row-span-full lg:h-full'>
          {image?.src && (
            <Images
              className='object-fill'
              src={getImageSrc(image?.src, '656')}
              addSrcSet={false}
              alt={image?.altText}
              fill
              sizes='656px'
            />
          )}
        </div>
      )}
      <div
        className={classNames({
          'py-8 md:py-10': isMarketingTile,
          'py-8 md:py-10 md:px-20': isCartTypeProductCard && !isMarketingTile,
          'py-12 md:py-20': isCartTypeProjectCard,
          'py-8 md:py-20 grid-col': isCardTypeCategoryCard,
          '!py-4': !hasHeading && !hasImage,
          'row-span-full !px-4 !py-8 lg:self-center lg:!pl-12 lg:!pr-20 lg:!py-16 2xl:!p-20':
            hasImage,
          [styles.cardsContainer]: hasImage,
        })}>
        {hasHeading && (
          <div
            className={classNames('relative', {
              [styles.categoryCardHeadingWrapper]: isCardTypeCategoryCard,
              [styles.projectCardHeadingWrapper]: isCartTypeProjectCard,
            })}>
            <div
              className={classNames(
                'flex flex-col mx-4 md:mx-20 gap-2 mb-8 lg:mb-4',
                { 'items-center': !isMarketingTile }
              )}>
              <Typography
                tag={TypographyTag.h3}
                variant={TypographyVariant.Heading3}
                className={styles.title}>
                {title}
              </Typography>
              {paragraph && (
                <Typography
                  tag={TypographyTag.p}
                  variant={TypographyVariant.BodyRegularExtraLineHeight}
                  className={classNames('text-secondary-muted')}>
                  {paragraph}
                </Typography>
              )}
            </div>
          </div>
        )}
        {cards?.length > 0 && (
          <>
            <div
              className={classNames('hidden md:block', {
                'mx-20': isMarketingTile,
              })}>
              {isDesktopCarouselDisabled ? (
                <div
                  className='grid gap-4'
                  style={{
                    gridTemplateColumns: `repeat(${rowItemCountOnDesktop}, minmax(0, 1fr))`,
                  }}>
                  {cards?.map((card, i) => {
                    return (
                      <React.Fragment key={i}>
                        {card.cardType === CardTypes.CATEGORY && (
                          <CategoryCard
                            {...card}
                            key={i}
                            isDesktopCarouselDisabled={
                              isDesktopCarouselDisabled
                            }
                            categorySku={categorySku}
                          />
                        )}
                        {card.cardType === CardTypes.PRODUCT && (
                          <ProductCard
                            {...card}
                            key={i}
                            showCompactAddToCart={width === 'narrow'}
                          />
                        )}
                        {card.cardType === CardTypes.PROJECT && (
                          <ProjectCard {...card} key={i} />
                        )}
                      </React.Fragment>
                    )
                  })}
                </div>
              ) : (
                <CardSwiper
                  seeMoreUrl={seeMoreUrl}
                  marketingTile={marketingTile}
                  marketingTileAtStart={marketingTileAtStart}
                  marketingTileAtEnd={marketingTileAtEnd}
                  isMarketingTile={isMarketingTile}
                  isCardTypeCategoryCard={isCardTypeCategoryCard}
                  cards={cards}
                  width={width}
                  setIsLoaded={setIsLoaded}
                />
              )}
            </div>
            <div
              className={classNames('md:hidden', {
                'mx-4': isMarketingTile,
              })}>
              {isMobileCarouselDisabled ? (
                <div
                  className='grid gap-4'
                  style={{
                    gridTemplateColumns: `repeat(${rowItemCountOnMobile}, minmax(0, 1fr))`,
                  }}>
                  {cards?.map((card, i) => {
                    return (
                      <React.Fragment key={i}>
                        {card.cardType === CardTypes.CATEGORY && (
                          <CategoryCard {...card} key={i} />
                        )}
                        {card.cardType === CardTypes.PRODUCT && (
                          <ProductCard {...card} key={i} />
                        )}
                        {card.cardType === CardTypes.PROJECT && (
                          <ProjectCard
                            {...card}
                            key={i}
                            isMobileCarouselDisabled={isMobileCarouselDisabled}
                          />
                        )}
                      </React.Fragment>
                    )
                  })}
                </div>
              ) : (
                <CardSwiper
                  seeMoreUrl={seeMoreUrl}
                  marketingTile={marketingTile}
                  marketingTileAtStart={marketingTileAtStart}
                  marketingTileAtEnd={marketingTileAtEnd}
                  isMarketingTile={isMarketingTile}
                  isCardTypeCategoryCard={isCardTypeCategoryCard}
                  cards={cards}
                  width={width}
                  setIsLoaded={setIsLoaded}
                />
              )}
            </div>
          </>
        )}

        {seeMoreText && seeMoreUrl && !isMarketingTile && (
          <div
            className={classNames(
              'flex justify-center mt-12 lg:mt-10 mx-auto',
              {
                'lg:!mt-6': isCartTypeProductCard,
              }
            )}>
            <Button
              status='secondary'
              href={seeMoreUrl}
              size='xs'
              theme={{ root: classNames(styles.cardBtn, 'mx-4') }}>
              <Typography
                tag={TypographyTag.span}
                variant={TypographyVariant.BodyRegularBold}
                className='normal-case text-primary-heading'>
                {seeMoreText}
              </Typography>
            </Button>
          </div>
        )}
      </div>
    </SectionContainer>
  )
}
